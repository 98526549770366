<template>
  <div class="helpcenter flex column">
    <!-- 顶部 start-->
    <div class="top_header">
      <div class="image_veiw cursor flex j-center a-center m-left-30" @click="$router.push('/order/orderMananger/orderList')">
        <img src="../../static/logo.png" style="width:76px;height:51px">
        <div class="m-left-20">帮助中心</div>
      </div>
      <div class="search flex j-end a-center m-right-20 SelectBoxModel3">
        <el-input
          v-model="value"
          class="input_s"
          placeholder="请输入内容"
          size="small"
        >
          <template slot="append">搜   索</template>
        </el-input>
      </div>
    </div>
    <!-- 顶部 end -->
    <div class="flex main j-between">
      <!-- Nav start-->
      <div class="main-left flex a-center column p-lr-20">
        <el-tree class="HelpMenuList" :render-content="renderContent" accordion style="width:100%" :data="list" @node-click="handleNodeClick" />
      </div>
      <!-- Nav end-->
      <!-- View start-->
      <div class="view_ ">
        <div class="Page_bg">
        <div class="title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index">{{ item.label }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div v-if="html" class="html" @click="replayImgShow($event)" v-html="html" width="100%" />
        <div v-else class="m-top-30">
          <el-empty description="暂无使用说明" />
        </div>
       </div>
      </div>
    <!-- View end-->
    </div>
    <el-image-viewer v-if="showViewer" style="z-index:9999" :url-list="[url]" :on-close="() => showViewer = false" />
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'HelpCenter1',
  components: {
    ElImageViewer
  },
  data() {
    return {
      value: '',
      list: [],
      html: '',
      url: '',
      showViewer: false,
      breadcrumb: []
    }
  },
  mounted() {
    this.GetHelpCenterCatalogueList()
  },
  methods: {
    test() {
      console.log(231321312312123213132)
      const dom = document.getElementById('pp')
      console.log(dom)
    },
    handleNodeClick(dom) {
      console.log(dom)
      if (dom.ParentID === 0) {
        this.breadcrumb = []
      }
      if (Array.isArray(dom.children)) {
        if (dom.children.length > 0) {
          this.breadcrumb = []
        }
      }
      this.setArr1(dom)
      // if (dom.children && dom.children.length > 0) return false
      this.breadcrumb.push({ label: dom.label, children: dom.children })
      // this.breadcrumb = [...new Set(this.breadcrumb)]
      this.breadcrumb = this.setArr(this.breadcrumb)
      this.GetHelpContentInfo(dom.IDX)
    },
    setArr(arr) {
      var obj = {}
      arr = arr.reduce(function(item, next) {
        obj[next.label] ? '' : obj[next.label] = true && item.push(next)
        return item
      }, [])
      return arr
    },
    setArr1(dom) {
      if (this.breadcrumb.length > 1) {
        console.log(this.breadcrumb[this.breadcrumb.length - 1])
        if (dom.children === this.breadcrumb[this.breadcrumb.length - 1].children) {
          this.breadcrumb.splice(this.breadcrumb.length - 1, 1)
        }
      }
    },
    renderContent(h, { node, data, store }) {
      let classname = ''
      if (node.childNodes.length === 0) {
        classname = 'lastDom'
      }
      return <span class={classname}>{node.label}</span>
    },
    // 获取左侧列表
    GetHelpCenterCatalogueList() {
      this.$api.GetHelpCenterCatalogueList().then(res => {
        console.log(res)
        if (res.RetCode !== '0') {
          return this.$message.error('错误：' + res.RetMsg)
        }
        this.list = res.Message
        this.GetHelpContentInfo(this.list[0].IDX)
        this.handleNodeClick(this.list[0])
      })
    },
    // 获取内容
    GetHelpContentInfo(idx) {
      this.$api.GetHelpContentInfo({
        dicIDX: idx
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') {
          return this.$message.error('错误：' + res.RetMsg)
        }
        if (!res.Message) {
          return this.html = ''
        }
        if (!res.Message.Content) {
          return this.html = ''
        }
        this.html = res.Message.Content
      })
    },
    replayImgShow(e) {
      if (e.target.tagName === 'IMG') {
        this.url = ''
        var imgObj = e.target
        var imgName = imgObj.getAttribute('src')
        this.url = imgName
        this.showViewer = true
      }
    }
  }
}
</script>
<style >
.input_s .el-input-group__append {
  border: none;
  background-color: #fff;
  color: #1989FA;
  cursor: pointer;
}
.lastDom {
    width: auto;
    height: auto;
  }
  .lastDom::before {
    content: "";
    display: inline-block;
    background-color: #ccc;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-bottom: 4px;
    margin-right: 8px;
  }
</style>
<style lang="scss" scoped>
.top_header {
    width: 100%;
    height: 80px;
    // min-width: 768px;
    background: #fff;
    margin: 0;
    color: #333;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content:space-between;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,.1);
    z-index: 9999;
    font-size: 24px;
    font-weight: bolder;
    border-top: 2px #409EFF solid;
    .image_veiw{
        width:  200px;
        height: 100%;
    }
    .search{
       flex:1;
       .input_s {
         width:500px;
          border-radius: 20px;
          background: #f4f4f4;
          overflow: hidden;
       }
    }
}
.main-left {
  width: 200px;
  height: calc(100vh - 80px);
  margin-top: 80px;
  background-color: #F5F6FA;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
}
.view_ {
  width: calc(100% - 200px);
  margin-left: 240px;
  margin-top: 80px;
  padding: 20px;
  overflow: auto;
  background-color:#DDE3F1;
  box-sizing: border-box;
  .Page_bg{ background: #fff;}
  .title {
    width: auto;
    // background-color: green;
    padding: 20px;
    border-bottom: 1px #eee solid;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
  }
  .html {
     height: auto;
     width: 100%;
    //  display: flex;
     overflow: auto;
    //  justify-content: center;
    //  flex-direction: column;
    //  align-items: center;
    padding: 10px 150px 50px 150px;
    height: calc(100vh - 175px);
    box-sizing: border-box;
  }
}
.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
